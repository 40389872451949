import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import React, {lazy, useRef, useEffect, useState, Suspense} from 'react';
import _ from 'underscore';
import env from './admin_env';
import Smart from './libs/Smart'
import Table from './libs/Table'
import Skeleton from './libs/Skeleton'
import Login from './libs/Login/Login'
import DefOne from 'libs/DefOne'
import DefList from 'libs/DefList'
import Fetcher from './comps/methods/Fetcher'

import {
    createBrowserRouter,
    RouterProvider,
    redirect,
    useLocation,
    useNavigate,
} from "react-router-dom";

// import WS from "./comps/WS";
import ContractModal from "./comps/Main/ContractModal";
import TransactionModal from "./comps/Main/TransactionModal";
import UserModal from "./comps/Main/UserModal";
import AccountModal from "./comps/Main/AccountModal";
import {statusObj} from "./comps/Main/methods";
import Stringify from "./comps/Stringify";
import {paidStatuses} from "./comps/Main/TransactionEdit";

export let isGoodRole = (type) => {
    let finRole = global?.user?.get_info()?.customData?.finRole;

    let def = ['tl', 'account'];
    let types = {
        sign: ['account'],
        topStats: ['account', 'tl']
    }
    return (types[type] || def).indexOf(finRole) > -1
}
window.Date.prototype.toODB = function () {

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    return this.getUTCFullYear() +
        '-' + pad(this.getUTCMonth() + 1) +
        '-' + pad(this.getUTCDate())
};

let files = require.context('./comps', true, /\.(js|jsx)$/).keys();
global.Fetcher = Fetcher;

function pub_menu(arr) {
    return arr.map(it => {
        return it.name ? it : {name: it, url: '/' + it.toLowerCase()}
    })
}

function to_url_arr(obj) {
    let arr = [];
    _.each(obj, (item, ind) => {

        let url = item.admin_url || ind;

        function capitalizeFirstLetter(string) {
            return ((string + '').charAt(0) || '').toUpperCase() + string.slice(1);
        }

        function preParse(arr, def) {
            return _.map(arr, (it, ind) => {
                if (typeof it === 'string') {
                    let arr = it.split('.');
                    let last = arr.at(-1);
                    let types = {
                        _num: 'number',
                        _date: 'date',
                        _bool: 'checkbox'
                    }
                    let getType = (v) => types[v] || v;
                    let isNum = (v) => v == +v;
                    let isType = (v) => /input|textarea|date|text|HR|number|_num|_date|_bool/gi.test(v) || isNum(v);
                    let startInd = 2;
                    let v = {};

                    if (isType(arr[0])) {
                        startInd = 0;
                    } else if (isType(arr[1])) {
                        v.name = capitalizeFirstLetter(arr[0])
                        v.key = arr[0]
                        startInd = 1;
                    } else {
                        v.name = capitalizeFirstLetter(arr[0])
                        v.key = arr[1] || arr[0]
                        startInd = 2;

                    }
                    v.type = isNum(arr[startInd]) ? def : getType(arr[startInd]);

                    if (isNum(last)) {
                        v.size = last;
                    }

                    return v
                } else {
                    return it;
                }
            })
        }

        item.tabs = preParse(item.tabs, 'text')
        item.edit = preParse(item.edit, 'input')

        arr.push({
            path: url,
            element: <DefList props={item}>
            </DefList>
        })

        arr.push({
            path: url + "/:id",
            element: <DefOne props={item}>
            </DefOne>

        })
    })
    return arr;
}


global.CONFIG = {
    menu: pub_menu(['Profile',
        {name: 'StatsConfig', url: 'statsconfig'},
        'HR', 'Logout']),
    //
    header: pub_menu([
        {name: 'Главная', url: 'main'},

        // 'MainContracts', 'Stats', 'Zp',
        isGoodRole('topStats') ?
            {name: 'Статистика', url: 'stats'}
            : '',
        // 'Accounts',
        // {
        //     name: 'Teams',
        //     url: 'tt'
        // },
        {name: 'Сотрудники', url: 'employees'},
        {name: 'Контракты', url: 'contracts'},
        //
        //
        // 'Employees',
        // // 'Transactions',
        // // 'EmplHistory',
        // 'Contracts', 'StatsConfig',
        // // 'TH'
    ].filter(it => it)),

    urls: {
        aa: {
            url: '/account',
            edit: [
                'name.12',
                {type: 'select', items: ['', 'RUR', 'USD', 'EUR'], key: 'currency', name: 'Currency'},
                {type: 'select', items: ['', 'real', 'virtual'], key: 'type', name: 'Type'},
                statusObj,
            ],
            tabs: [
                '_id',
                'name',
                'type',
                'status',
                'currency',
                'teams',
            ]
        },
        tt: {
            url: '/team',
            edit: [
                'name.12',
                'hash.4',
                {...statusObj, size: 4},
                {name: 'Type', key: 'type', size: 4, type: 'select', items: ['', 'head', 'office', 'team']},
            ],
            tabs: [
                '_id',
                'status',
                'type',
                'hash',
                'name',
                'admins',
            ]
        },
        uu: {
            url: '/user',
            edit: [
                // 'name.12',
                'username.4',
                'plain_password.4',
                // {key: 'customData.fieldName', name: 'CustomData'},
                {type: 'checkbox', key: 'customData.isAllTeams', name: 'isAllTeams', size: 2},
                {type: 'select', key: 'customData.finRole', name: 'finRole', size: 12, items: ['', 'om', 'sale', 'tl', 'account']},

                {
                    path: 'Main/MyAutocomplete',
                    key: 'teams',
                    isMulti: true,
                    itemsKey: 'allTeams',
                    label: 'TeamFrom',
                    size: 12
                },
            ],
            tabs: [
                '_id',
                'username',
                'teams',
                {key: 'customData.finRole', name: 'finRole'},
                {
                    name: 'isAll?', key: 'customData.isAllTeams', Component({item}) {
                        return item?.customData?.isAllTeams ? 'all' : '-'
                    }
                },
            ]
        },
        employees: {
            url: '/employee',
            edit: [

                {type: 'date', name: 'Начал стажировку', key: 'startEducation', size: 4},
                {type: 'date', name: 'Подписал договор', key: 'startWork', size: 4},
                {type: 'date', name: 'Окончание сотрудничества', key: 'end', size: 4},
                {type: 'input', name: 'Фамилия', key: 'surName', size: 4},
                {type: 'input', name: 'Имя', key: 'name', size: 4},
                {type: 'input', name: 'Отчество', key: 'parentName', size: 4},
                {...statusObj, size: 4},
                {
                    size: 4,
                    type: 'select', items: ['', 'ooo', 'agent', 'gph'],
                    name: 'Тип трудоустройства',
                    key: 'workerType',
                },

            ],
            tabs: [
                '_id',
                'name',
                'surName',
                'parentName',
                'workerType',
                'status',
                'startEducation',
                'startWork',
                'end',
            ]
        },
        transactions: {
            url: '/transaction',
            edit: [
                'name',
                'type',
                'category',
                'comment',
                // 'accountFrom',
                // 'accountTo',
                'amountFrom.number',
                'amountTo.number',
                'HR.12',
                'period',
                'periodFrom',
                'periodTo',
                {
                    defSize: 6,
                    size: 6,
                    childs: [
                        {path: 'Main/MyAutocomplete', key: 'teamFrom', itemsKey: 'teams', label: 'TeamFrom'},
                        {path: 'Main/MyAutocomplete', key: 'accountFrom', itemsKey: 'accounts', label: 'Account'},
                        {path: 'Main/MyAutocomplete', key: 'teamTo', itemsKey: 'teams', label: 'TeamTo'},
                        {path: 'Main/MyAutocomplete', key: 'accountTo', itemsKey: 'accounts', label: 'Account'},

                    ]
                },

                {
                    size: 12,
                    Component({item}) {
                        return <Stringify item={item}></Stringify>
                    }
                }
            ],
            tabs: [
                '_id',
                'name',
                'type',
                'period',
                'category',
                'comment',
                'accountFrom',
                'accountTo',
                'amountFrom',
                'amountTo',
            ]
        },
        th: {
            url: '/transaction-history',
            edit: [
                'name',
                'type',
                'status',
                'account',
                {
                    size: 12,
                    Component({item}) {
                        return <Stringify item={item}></Stringify>
                    }
                }
            ],
            tabs: [
                '_id',
                'type',
                'name',
                'status',
                'user',
                'transaction',
                'teamTo',
                'teamFrom',
                'accountFrom',
                'acountTo',
                'amountFrom',
                'amountTo',
                'cd',
            ]
        },
        emplhistory: {
            url: '/employee-history',
            edit: [
                'name',
                'comment',
                'employee',
                'type',
                'period',
                'actionDate _date',
            ],
            tabs: [
                'name',
                'comment',
                'employee',
                'type',
                'period',
                'actionDate _date',
            ]
        },
        pp: {
            url: '/position',
            edit: [
                'name',
                'comment',
                'type',
            ],
            tabs: [
                'name',
                'comment',
                'type',
            ]
        },
        contracts: {
            url: '/contract',
            edit: [
                {type: 'input', key: 'name', name: 'Название контракта', size: 6},
                {type: 'date', key: 'start', name: 'Начала контракта'},
                {type: 'date', key: 'end', name: 'Окончание контракта'},
                {...statusObj, size: 6},
                {type: 'number', key: 'daysToPayMain', name: 'Дней главная оплата', size: 3},
                {type: 'number', key: 'daysToPayAvance', name: 'Дней аванс оплата', size: 3},
                {type: 'textarea', key: 'comment', name: 'Комментарий', size: 6},
            ],
            tabs: [
                'name',
                'comment',
                'status',
                'daysToPayMain',
                'daysToPayAvance',
                'start',
                'end',
                'info *',
            ]
        },
        payCategories: {
            url: '/pay-category',
            edit: [
                'name',
                'comment',
                statusObj,
                'payCategoryGroup',
                'info *',
                {path: 'Main/MyAutocomplete', key: 'types', isMulti: true, itemsKey: 'types', label: 'Тип'},

            ],
            tabs: [

                'name',
                'comment',
                'status',
                'payCategoryGroup',
                'info *',
            ]
        },

    }
}

let admin_urls = to_url_arr(global.CONFIG.urls);
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                path: "profile",
                element: Loader('Profile')()
            },
            {
                path: 'main',
                element: Loader("Main/Main")()
            }, {
                path: 'mainContracts',
                element: Loader("Main/MainContracts")()
            }, {
                path: 'stats',
                element: Loader("Main/Stats/Stats")()
            }, {
                path: 'statsconfig',
                element: Loader("Main/StatsConfig")()
            }, {
                path: 'zp',
                element: Loader("Main/ZP")()
            }

        ].concat(admin_urls),
    }, {
        path: '404',
        element: <div>404</div>
    }, {
        path: 'login',
        element: <Login/>
    }
]);

function sum2elements(a, b) {
    return a + b;
}

function Loader(path) {

    function def() {
        return function (props) {
            return <Skeleton label={path}></Skeleton>
        }
    }

    try {
        let _path = './' + path.replace(".js", '').replace('./', '').replace(/^\//gi, '') + '.js';

        if (files.indexOf(_path) > -1) {
            let Comp = require('./comps/' + path).default
            return function (props) {
                return <Comp props={props}></Comp>
            }
        } else {
            console.log('*........ ## AA FALSE', files);
            return def()

        }

    } catch (e) {
        console.log('*........ ## root eee', e);
        return def()
    }
}

global.Loader = Loader;

window.save = {}

function MainModals() {
    let modalId = useRef(0)
    let [cd, setCd] = useState(0)
    let [items, setItems] = useState([
        // {type: 'contract', item: {_id: 123}, modalId: 1},
        // {type: 'user', item: {_id: 123}, modalId: 2},
        // {type: 'account', item: {_id: 123}, modalId: 3},
        // {type: 'transaction', item: {_id: 123}, modalId: 4},
    ])
    window.mitems = items;


    window.hideModal = ({modalId}) => {
        setItems(items => {
            return items.filter((it) => it.modalId != modalId)
        })
    }
    window.openMainModal = (v) => {
        setItems((items) => {
            // items.push({type: v.type, modalId: ++modalId.current, item: v.item, onChange: v.onChange})
            return [...items, {modalId: ++modalId.current, ...v}];
        })
    }
    window.reloadApp = () => setCd(new Date())

    return <>
        {(items || []).map((it, ind) => {
            let modals = {
                transaction: TransactionModal,
                user: UserModal,
                account: AccountModal,
                contract: ContractModal,
            }
            let {type, modalId} = it;
            let Component = modals[type];
            if (!Component) {
                alert("WRONG TYPE = " + type)
                return;
            }

            return (<div key={ind}>
                <Component {...it}></Component>
            </div>)
        })}

    </>
}


let syncCd = 0;
function Root() {

    let [info, setInfo] = useState(null)
    let location = useLocation();
    const navigate = useNavigate();


    global.navigate = navigate;
    global.redirect = redirect;

    React.useEffect(() => {
        // console.log('*........ ## location changed');
    }, [location]);

    let path = /team/gi.test(window.location.pathname)
        ? 'Layouts/Layout2'
        : 'Layouts/Layout1';
    let Item = Loader(path);

    if (window.location.pathname == '/') {
        setTimeout(() => {
            navigate('/main')
        }, 100)
    }

    useEffect(() => {
        start().then();
    }, [])


    function start() {
        return new Promise(async (resolve, reject) => {
            if (new Date().getTime() - syncCd < 3000) {
                return resolve();
            }
            syncCd = new Date().getTime();

            let query = {per_page: 10000}
            let arr = await Promise.all([
                global.http.get('/account', query),
                global.http.get('/my-team', query),
                global.http.get('/employee', query),
                global.http.get('/contract', query),
                global.http.get('/pay-category', query),
                global.http.get('/position', query),
                global.http.get('/team', query),
                global.http.get('/user', query),
            ])
            let set = (ind) => {
                // console.log("qqqqq arrrrrrrrrrrrr", arr);
                return (arr[ind] || {}).items || []
            }

            let info = {
                accounts: set(0),
                virtualAccounts: set(0).filter(it => it.type === 'virtual'),
                myTeams: set(1),
                teams: set(1),
                virtualTeams: set(1).filter(it => it.type === 'head'),
                users: set(7),
                employees: set(2),
                contracts: set(3),
                categories: set(4),
                positions: set(5),
                allTeams: set(6),
                types: [
                    {name: '-', _id: ''},
                    {name: 'Оклад', _id: 'oklad'},
                    // {name: 'Премия', _id: 'premia'},
                    {name: 'Корп налог офис', _id: 'nalog_office'},
                    // {name: 'Год Премия', _id: 'god_premia'},
                    // {name: 'Налог Оклад', _id: 'oklad_nalog'},
                    // {name: 'Налог Премия', _id: 'premia_nalog'},
                    // {name: 'Налог ГП', _id: 'year_premia_nalog'},
                    {name: 'Распределения', _id: 'raspredi'},

                    {name: 'Перевод', _id: 'perevod'},
                    {name: 'Перевод Smart', _id: 'perevod_smart'},
                    {name: 'Приход', _id: 'prihod'},
                    {name: 'Траты', _id: 'trati'}],
                deepTypes: [
                    {name: '-', _id: ''},
                    {name: 'Налог Р', _id: 'raspredi_nalog'},
                    {name: 'Налог', _id: 'oklad_nalog'},
                    {name: 'Налог П', _id: 'premia_nalog'},
                    {name: 'Премия', _id: 'premia'},
                    {name: 'Премия Год', _id: 'year_premia'},
                    {name: 'Налог ГП', _id: 'year_premia_nalog'},
                    {name: 'Корп. Налог', _id: 'prihod_nalog'},

                ],
                subTypes: [
                    {name: '-', _id: ''},
                    {name: 'Аренда', _id: 'arenda'},
                    {name: 'Снабжение офиса', _id: 'snabzenie'},
                    {name: 'Техника', _id: 'tech'},
                    {name: 'Сервисы', _id: 'services'},
                    {name: 'Лицензия', _id: 'licence'},
                    {name: 'Налоги/Патент', _id: 'nalog'},
                    {name: 'Бух сервисы', _id: 'acc_services'},
                    {name: 'ДР/Корпоративы', _id: 'dr'},
                    {name: 'Коммандировки', _id: 'kommandirovki'},
                    {name: 'Корпоративы', _id: 'korporat'},
                    {name: 'Разное', _id: 'other'},

                ],
                paidStatuses: paidStatuses,
            };
            info = Object.keys(info).reduce((acc, it) => {
                return {
                    ...acc, [it + 'Obj']: info[it].reduce((acc, it) => {
                        return {...acc, [it._id || it.value]: it}
                    }, {})
                }
            }, info)
            setInfo(info)
        })

    }
    window.start = start;

    // let info = {accounts, teams, employees, contracts}
    window.info = info;
    if (!info) {
        return <div style={{textAlign: 'center', padding: '10%'}}>Loading ...</div>
    }

    return <div>
        <MainModals></MainModals>
        {/*<WS></WS>*/}
        <Item></Item>
    </div>
}


createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
);

reportWebVitals();


function Team(props) {
    return <div>Commented</div>
}
